import {autoserialize, autoserializeAs} from 'cerialize';
import {CustomWorkflowStepModel} from './custom-workflow-step.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {ICustomWorkflowCardNavigationItem} from '../interfaces/custom-workflow-card-navigation-item.interface';
import {EPageType, TemplateModel} from './template.model';
import {PublicationItemContentModel} from './publication-item-content.model';
import {CampaignItemModel} from './campaign-item.model';
import {ETableMediaType, ITableItem, ITableMedia} from '@relayter/rubber-duck';
import {RLDatePipe} from '../../pipes/rl-date.pipe';
import {AppConstants} from '../../app.constants';
import {FileModel} from './file.model';
import {UserModel} from './user.model';
import {LayoutNoteModel} from './layout-note.model';
import {AssetFileTypePipe} from '../../pipes/asset-file-type.pipe';

export class PublicationItemFilesModel {
    @autoserializeAs(FileModel) public thumb: FileModel;
    @autoserializeAs(FileModel) public preview: FileModel;
    @autoserialize public export: string;
    @autoserializeAs(FileModel) public source: FileModel;
    @autoserialize public sourceCreatedAt: string;
}

// only used here
class PackageItemDataModel {
    @autoserialize public _id: string;
    @autoserializeAs(Object) public dataFields: Record<string, any>;
}

export enum ESignOffStatus {
    APPROVED = 'APPROVED'
}

export class SignOffUserModel {
    @autoserializeAs(UserModel) public user: UserModel;
    @autoserialize public date: Date;
    @autoserialize public status: ESignOffStatus;
}

export class SignOffModel {
    @autoserializeAs(SignOffUserModel) public signOffUsers: SignOffUserModel[];
}

export class ContentRevisionModel implements IDropdownItem<PublicationItemContentModel[]> {
    @autoserialize public date: Date;
    @autoserialize public revision: string;
    @autoserializeAs(PublicationItemContentModel) public content: PublicationItemContentModel[];

    getTitle(): string {
        return `${this.revision} - ${RLDatePipe.format(this.date, RLDatePipe.dateFormats.TABLE_DETAILED)}`;
    }

    getValue(): PublicationItemContentModel[] {
        return this.content;
    }
}

export class VariantsFileModel {
    @autoserializeAs(PublicationItemFilesModel) public files: PublicationItemFilesModel;
    @autoserialize public variant: string;
}

export enum EContentChangeAction {
    REMOVED = 'REMOVED',
    ADDED = 'ADDED',
    EDITED = 'EDITED'
}

export class ContentChangeModel {
    public type: string;
    public action: EContentChangeAction;
    // for Added content, contentItem is the newly added item.
    // for Removed content, contentItem is the removed item.
    // for Edited content, contentItem is the item before edited.
    public contentItem: PublicationItemContentModel;
}

export class FilesRevisionModel implements IDropdownItem<PublicationItemFilesModel> {
    @autoserialize public date: Date;
    @autoserialize public revision: string;
    @autoserializeAs(PublicationItemFilesModel) public files: PublicationItemFilesModel;
    @autoserialize public variant: string;

    getTitle(): string {
        return `${this.revision} - ${RLDatePipe.format(this.date, RLDatePipe.dateFormats.TABLE_DETAILED)}`;
    }

    getValue(): PublicationItemFilesModel {
        return this.files;
    }
}

export class PublicationItemAttachmentFilesModel {
    @autoserialize public thumbnail: string;
    @autoserialize public source: string;
}

export class PublicationItemAttachmentModel {
    @autoserialize public _id: string;
    @autoserialize public fileName: string;
    @autoserialize public size: string;
    @autoserializeAs(PublicationItemAttachmentFilesModel) public files: PublicationItemAttachmentFilesModel;
}

export class PublicationItemLinkModel {
    @autoserialize public url: string;
    @autoserialize public variant: string;
}

export class PublicationItemModel implements IDropdownItem, ICustomWorkflowCardNavigationItem, ITableItem {
    @autoserialize public _id: string;
    @autoserialize public publicationItemId: string; // TODO: We probably want to refactor this to filename
    @autoserialize public publication: string;
    @autoserializeAs(TemplateModel) public template: TemplateModel;
    @autoserializeAs(PublicationItemContentModel) public content: PublicationItemContentModel[];
    @autoserializeAs(VariantsFileModel, 'files') public files: VariantsFileModel[];
    @autoserialize public firstPageNumber: number; // TODO: Move to Interface?
    @autoserialize public numberOfPages: number;
    @autoserializeAs(CustomWorkflowStepModel) public step: CustomWorkflowStepModel;
    @autoserialize public inTransition: boolean;
    @autoserialize public transitionItemId: string;
    @autoserialize public tags: string[];
    @autoserialize public createdAt: Date;
    @autoserialize public updatedAt: Date;
    @autoserializeAs(SignOffModel) public signOff: SignOffModel;
    @autoserializeAs(CampaignItemModel) public campaignItems: CampaignItemModel[];
    @autoserializeAs(ContentRevisionModel) public contentRevisions: ContentRevisionModel[];
    @autoserializeAs(FilesRevisionModel, 'filesRevisions') public filesRevisions: FilesRevisionModel[];
    @autoserializeAs(Date) public deadline: Date;
    @autoserializeAs(PublicationItemAttachmentModel) public attachments: PublicationItemAttachmentModel[] = [];
    @autoserializeAs(PublicationItemLinkModel) public links: PublicationItemLinkModel[] = [];
    @autoserializeAs(LayoutNoteModel) public layoutNotes: LayoutNoteModel[] = [];
    @autoserialize public numberOfAssignedItems: number;

    public previewImage = '';
    public sourceFile = '';
    public previewType: string = '';
    public originalPageNumber: number;

    // calculated field, DO NOT USE PackageItemModel to avoid Circular dependency
    @autoserializeAs(PackageItemDataModel) public packageItem: PackageItemDataModel;
    // field that's used to link briefing changes, not from backend
    public changedCampaignItems: CampaignItemModel[] = [];

    // Normally only used for workflow with sequentialNumbering true
    get formattedPageNumbers(): string {
        return this.numberOfPages === 1
            ? this.firstPageNumber.toString() : `${this.firstPageNumber}-${this.firstPageNumber + this.numberOfPages - 1}`;
    }

    get pageType(): EPageType {
        return this.numberOfPages === 1 ? EPageType.Single : EPageType.Spread;
    }

    public getVariantFilesRevisions(variant?: string): FilesRevisionModel[] {
        if (variant) {
            return this.filesRevisions?.filter((filesRevisions: FilesRevisionModel) => filesRevisions.variant === variant) || [];
        }
        return this.filesRevisions || [];
    }

    public getVariantFiles(variant?: string): PublicationItemFilesModel {
        if (variant) {
            const variantFile = this.files.filter((files: VariantsFileModel) => files.variant === variant)[0];
            return variantFile?.files;
        } else {
            return this.files[0]?.files;
        }
    }

    public getVariantLink(variant?: string): PublicationItemLinkModel {
        return this.links?.find((link: PublicationItemLinkModel) => link.variant === variant || (!variant && !link.variant));
    }

    /**
     * Returns a display title for use in the custom workflow upload component
     * @return {string}
     */
    public getUploadDisplayName(sequentialNumbering: boolean): string {
        if (sequentialNumbering) {
            return `Page ${this.formattedPageNumbers}`;
        }

        // For now, we pick the first content campaign item and get the briefing item id
        if (Array.isArray(this.content)) {
            for (const content of this.content) {
                if (content.campaignItem) {
                    return content.campaignItem.briefingItemId;
                }
            }
        }

        return null;
    }

    /**
     * Interface function
     * @return {string}
     */
    public getTitle(sequentialNumbering?: boolean): string {
        return sequentialNumbering ? `Page ${this.formattedPageNumbers}` : this.publicationItemId;
    }

    /**
     * Interface function
     * @return {string}
     */
    public getValue(): string {
        return this._id;
    }

    public getThumbnailURL(variant?: string): string {
        const files = this.getVariantFiles(variant);
        if (files && files.thumb) {
            return files.thumb.url;
        }

        if (this.numberOfPages === 1) {
            return '/assets/images/page_preview_placeholder_single-page.svg';
        } else {
            return '/assets/images/page_preview_placeholder_spread.svg';
        }
    }

    /**
     * Get the variant specific thumbnail if variant available
     * @param {string} variantId
     * @param {VariantsFileModel[]} files
     * @return {string}
     * @private
     */
    public static getThumbnailUrl(variantId: string, files: VariantsFileModel[]): string {
        if (!(Array.isArray(files) && files.length)) {
            return AppConstants.ICONS.IMAGE_MAIN;
        }

        if (variantId) {
            const variantFile = files?.find((item) => item.variant === variantId);
            return variantFile?.files.thumb ? variantFile.files.thumb.url : AppConstants.ICONS.IMAGE_MAIN;
        } else {
            return files[0]?.files.thumb ? files[0].files.thumb.url : AppConstants.ICONS.IMAGE_MAIN;
        }
    }

    /**
     * Get the variant specific table media data
     * @param {string} variantId
     * @param {VariantsFileModel[]} files
     * @return {ITableMedia}
     */
    public static getTableMediaObject(files: VariantsFileModel[], variantId?: string): ITableMedia {
        if (!(Array.isArray(files) && files.length)) {
            return {
                previewUrl: AppConstants.ICONS.IMAGE_MAIN,
                thumbUrl: AppConstants.ICONS.IMAGE_MAIN,
                type: ETableMediaType.IMAGE
            };
        }

        if (variantId) {
            const variantFile = files?.find((item) => item.variant === variantId);
            return {
                previewUrl: variantFile?.files?.preview?.url || AppConstants.ICONS.IMAGE_MAIN,
                thumbUrl:  variantFile?.files?.thumb?.url || AppConstants.ICONS.IMAGE_MAIN,
                type: (
                    AssetFileTypePipe.format(variantFile?.files?.thumb?.extension ||
                    variantFile?.files?.preview?.extension)?.toUpperCase() ||
                    ETableMediaType.IMAGE
                ) as ETableMediaType
            };
        }

        return {
            previewUrl: files[0]?.files?.preview?.url || AppConstants.ICONS.IMAGE_MAIN,
            thumbUrl: files[0]?.files?.thumb?.url || AppConstants.ICONS.IMAGE_MAIN,
            type: (
                AssetFileTypePipe.format(files[0]?.files?.thumb?.extension ||
                files[0]?.files?.preview?.extension)?.toUpperCase() ||
                ETableMediaType.IMAGE
            ) as ETableMediaType
        };
    }

    /**
     * Interface method
     * @return {string}
     */
    public getNavigationItemTitle(sequentialNumbering: boolean): string {
        if (sequentialNumbering) {
            if (this.originalPageNumber && this.originalPageNumber !== this.firstPageNumber) {
                const previousPageNumber = this.numberOfPages > 1
                    ? `${this.originalPageNumber} - ${this.originalPageNumber + this.numberOfPages - 1}`
                    : this.originalPageNumber;
                return `${this.getTitle(sequentialNumbering)} (previous ${previousPageNumber})`;
            } else {
                return this.getTitle(sequentialNumbering);
            }
        }
        return this.publicationItemId;
    }

    /**
     * Interface method
     * @return {string}
     */
    public getNavigationItemSubtitle(sequentialNumbering: boolean): string {
        return sequentialNumbering ? this.publicationItemId : this.template?.name;
    }

    /**
     * Interface method
     * @return {LayoutNoteModel[] | []}
     */
    public get layoutNoteItems(): LayoutNoteModel[] {
        return this.layoutNotes || [];
    }

    /**
     * Checks differences between 2 layouts and returns an array of changes
     * @param contentOld
     * @param contentNew
     */
    public static diffRevision(contentOld: PublicationItemContentModel[],
                               contentNew: PublicationItemContentModel[]): ContentChangeModel[] {
        const changes = [];

        contentOld.forEach((cItemOld) => {
            const change = new ContentChangeModel();
            change.type = cItemOld.contentType;

            const cItemNew = PublicationItemModel.findContentItem(cItemOld, contentNew);

            if (cItemNew === undefined) {
                change.action = EContentChangeAction.REMOVED;
                change.contentItem = cItemOld;
                changes.push(change);
            } else if (
                cItemNew.area?._id !== cItemOld.area?._id
                || (cItemOld.area && !cItemOld.area.isEqual(cItemNew.area))
            ) {
                change.action = EContentChangeAction.EDITED;
                change.contentItem = cItemOld;
                changes.push(change);
            }
        });

        contentNew.forEach((cItemNew) => {
            const cItemOld = PublicationItemModel.findContentItem(cItemNew, contentOld);

            if (cItemOld === undefined) {
                const change = new ContentChangeModel();
                change.type = cItemNew.contentType;
                change.action = EContentChangeAction.ADDED;
                change.contentItem = cItemNew;
                changes.push(change);
            }
        });

        return changes;
    }

    /**
     * Finds contentItem in array of content. Note matching will be done by note message. If an asset or briefing item
     * is in the layout twice it will also check the area _id.
     * @param contentItem
     * @param content
     */
    public static findContentItem(contentItem: PublicationItemContentModel, content: PublicationItemContentModel[]): PublicationItemContentModel {
        switch (contentItem.contentType) {
            case AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.CAMPAIGN_ITEM: {
                const item = content.find((c) =>
                    c.contentType === contentItem.contentType &&
                    c.campaignItem && contentItem.campaignItem &&
                    c.campaignItem._id === contentItem.campaignItem._id &&
                    // Could be from an animated content template
                    c.area?._id === contentItem.area?._id);

                if (item) {
                    return item;
                } else {
                    return content.find((c) =>
                        c.contentType === contentItem.contentType &&
                        c.campaignItem === contentItem.campaignItem);
                }
            }
            case AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.ASSET: {
                const item = content.find((c) =>
                    c.contentType === contentItem.contentType &&
                    c.asset && contentItem.asset &&
                    c.asset._id === contentItem.asset._id &&
                    c.area._id === contentItem.area._id);

                if (item) {
                    return item;
                } else {
                    return content.find((c) =>
                        c.contentType === contentItem.contentType &&
                        c.asset && contentItem.asset &&
                        c.asset._id === contentItem.asset._id);
                }
            }
            case AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.LAYOUT_NOTE:
                return content.find((c) =>
                    c.contentType === contentItem.contentType &&
                    c.layoutNote.message === contentItem.layoutNote.message);
        }
    }
}
