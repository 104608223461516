import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component, DestroyRef,
    ElementRef, inject, input,
    InputSignal, NgZone,
    OnDestroy, Signal, viewChild
} from '@angular/core';
import {StaticContentTemplateSizeModel} from './static-content-template-size.model';
import {PixiSizeIndicator} from './size-indicator.pixi';
import {PixiTemplateContent, PixiTemplateContentStatus} from './template-content.pixi';
import {PixiTemplate} from './template.pixi';
import {combineLatest} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {StaticContentTemplateEditorDataService} from './static-content-template-editor.data-service';
import {EChannel} from '../../../../../app.enums';
import {TemplateAreaModel} from '../../../../../models/api/template-area.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import ApplicationOptions = PIXI.ApplicationOptions;

function memoize<T extends (...args: any[]) => any>(fn: T): T {
    const cache = new Map();

    return ((...args: Parameters<T>): ReturnType<T> => {
        const key = JSON.stringify(args);

        if (cache.has(key)) {
            return cache.get(key);
        }

        const result = fn(...args);
        cache.set(key, result);
        return result;
    }) as T;
}

@Component({
    selector: 'static-content-template-editor',
    templateUrl: 'static-content-template-editor.component.html',
    styleUrls: ['static-content-template-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticContentTemplateEditorComponent implements AfterViewInit, OnDestroy {
    private static readonly EDITOR_MARGIN = 50;
    private static readonly PAGE_BACKGROUND_COLOR = 0xffffff;

    private destroyRef: DestroyRef = inject(DestroyRef);
    private ngZone: NgZone = inject(NgZone);

    public editorWidth: InputSignal<number> = input(800);
    public editorHeight: InputSignal<number> = input(600);

    private channel: EChannel;
    private templateSize: StaticContentTemplateSizeModel;
    private contents: TemplateAreaModel[];
    private backgroundUrl: string;

    private selectedContentIndex: number;
    private hoveredContentIndex: number;

    public editor: Signal<ElementRef> = viewChild.required('editor');

    private pixiApp: PIXI.Application;
    private backgroundStage: PIXI.Container = new PIXI.Container();
    private templateStage: PIXI.Container = new PIXI.Container();
    private contentStage: PIXI.Container = new PIXI.Container();
    private indicatorStage: PIXI.Container = new PIXI.Container();
    private scalingStage: PIXI.Container = new PIXI.Container();

    private scale = 1;
    private numberOfPages: number;
    private count: number = 0;

    public collisions: Set<TemplateAreaModel>;

    constructor(private templateEditorDataService: StaticContentTemplateEditorDataService) {
    }

    public ngAfterViewInit(): void {
        this.initializePixi();

        this.templateEditorDataService.backgroundUrlSubject.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((signedUrl) => {
            this.backgroundUrl = signedUrl;
            this.loadBackground();
        });

        const templateData$ = combineLatest({
            channel: this.templateEditorDataService.channelSubject,
            templateSize: this.templateEditorDataService.templateSizeSubject,
            numberOfPages: this.templateEditorDataService.numberOfPagesSubject,
            contents: this.templateEditorDataService.contentsSubject,
            selectedContentIndex: this.templateEditorDataService.selectedContentIndexSubject,
            hoveredContentIndex: this.templateEditorDataService.hoveredContentIndexSubject
        }).pipe(
            distinctUntilChanged((prev, curr) => {
                // Custom comparison logic for complex objects
                this.count++;
                return JSON.stringify(prev) === JSON.stringify(curr);
            }),
            takeUntilDestroyed(this.destroyRef)
        );

        templateData$.pipe(
            debounceTime(16) // 60FPS debounce
        ).subscribe(({
                         channel,
                         templateSize,
                         numberOfPages,
                         contents,
                         selectedContentIndex,
                         hoveredContentIndex
                     }) => {
            this.channel = channel;
            this.templateSize = templateSize;
            this.numberOfPages = numberOfPages;
            this.contents = contents;
            this.selectedContentIndex = selectedContentIndex;
            this.hoveredContentIndex = hoveredContentIndex;

            // Use RequestAnimationFrame for visual updates
            requestAnimationFrame(() => this.update());
        });
    }

    public ngOnDestroy(): void {
        if (this.pixiApp) {
            this.pixiApp.destroy(true, {
                children: true,
                texture: true,
                baseTexture: true
            });
        }
        PIXI.loader.reset();
    }

    private initializePixi(): void {
        const editorOptions: ApplicationOptions = {
            backgroundColor: StaticContentTemplateEditorComponent.PAGE_BACKGROUND_COLOR,
            resolution: window.devicePixelRatio,
            view: this.editor().nativeElement,
            sharedTicker: true,
            autoResize: true
        };

        this.pixiApp = new PIXI.Application(this.editorWidth(), this.editorHeight(), editorOptions);
        this.scalingStage.addChild(this.backgroundStage);
        this.scalingStage.addChild(this.templateStage);
        this.scalingStage.addChild(this.contentStage);

        this.pixiApp.stage.addChild(this.scalingStage);
        this.pixiApp.stage.addChild(this.indicatorStage);

        this.update();
    }

    private loadBackground(): void {
        // If a background url is set and the resource was not loaded before, add it to the loader
        if (this.backgroundUrl && !PIXI.loader.resources[this.backgroundUrl]) {
            PIXI.loader.add(this.backgroundUrl);
            PIXI.loader.load(() => this.update());
        } else {
            // If no background we can just refresh to remove any old background
            this.update();
        }
    }

    private update(): void {
        this.clearStages();
        this.ngZone.runOutsideAngular(() =>{

            if (!this.templateSize) {
                return;
            }

            // Scale the content, so we always see the complete template
            // Adjust width & height for to always leave some space on the sides
            const width = this.templateSize.width * this.numberOfPages;
            const height = this.templateSize.height;

            this.scale = Math.min((this.pixiApp.renderer.screen.width - StaticContentTemplateEditorComponent.EDITOR_MARGIN) / width,
                (this.pixiApp.renderer.screen.height - StaticContentTemplateEditorComponent.EDITOR_MARGIN) / height);

            this.drawTemplate();
            this.drawBackground();
            if (this.contents) {
                this.detectCollisions();
                this.drawContents();
            }
            this.drawIndicators();

            // Position baseStage in the center of the view
            this.scalingStage.pivot.x = this.scalingStage.width / 2;
            this.scalingStage.pivot.y = this.scalingStage.height / 2;
            this.scalingStage.x = this.pixiApp.renderer.screen.width / 2;
            this.scalingStage.y = this.pixiApp.renderer.screen.height / 2;

            // Position Indicator stage
            this.indicatorStage.x = this.scalingStage.x - this.scalingStage.width / 2;
            this.indicatorStage.y = this.scalingStage.y - this.scalingStage.height / 2;
        })
    }

    private clearStages(): void {
        while (this.templateStage.children.length > 0) {
            this.templateStage.removeChild(this.templateStage.children[0]).destroy(true);
        }
        // Remove and destroy all children from the backgroundStage
        while (this.backgroundStage.children.length > 0) {
            this.backgroundStage.removeChild(this.backgroundStage.children[0]).destroy(true);
        }
        while (this.contentStage.children.length > 0) {
            this.contentStage.removeChild(this.contentStage.children[0]).off('pointerdown').destroy(true);
        }
        while (this.indicatorStage.children.length > 0) {
            this.indicatorStage.removeChild(this.indicatorStage.children[0]).destroy(true);
        }
    }

    private detectCollisions(): void {
        this.collisions = this.memoizedCollisionDetection(this.contents);
        this.templateEditorDataService.setCollisions(this.collisions);
    }

    private drawBackground(): void {
        if (!this.backgroundUrl) { // If no background is set don't add a sprite
            return;
        }

        const resource = PIXI.loader.resources[this.backgroundUrl];
        if (!resource) { // If no resource, wait for the resource to load and next update call
            return;
        }

        const sprite = new PIXI.Sprite(resource.texture);
        this.backgroundStage.addChild(sprite);
        sprite.width = this.templateSize.width * this.scale * this.numberOfPages;
        sprite.height = this.templateSize.height * this.scale;
    }

    private drawTemplate(): void {
        const template = new PixiTemplate(this.templateSize, this.numberOfPages, this.scale);
        this.templateStage.addChild(template);
    }

    private drawContents(): void {
        for (let i = 0; i < this.contents.length; i++) {
            const content = this.contents[i];
            this.drawContent(content, i, this.collisions.has(content));
        }
    }

    private drawContent(content: TemplateAreaModel, index: number, collision: boolean): void {
        const templateContent = new PixiTemplateContent(content, index, this.scale);

        templateContent.on('pointerdown', () => this.templateEditorDataService.setSelectedContentIndex(index));

        if (this.selectedContentIndex >= 0) {
            templateContent.setStatus(this.selectedContentIndex === index ? PixiTemplateContentStatus.Active : PixiTemplateContentStatus.Inactive);
        } else if (this.hoveredContentIndex >= 0) {
            templateContent.setStatus(this.hoveredContentIndex === index ? PixiTemplateContentStatus.Active : PixiTemplateContentStatus.Inactive);
        } else {
            templateContent.setStatus(PixiTemplateContentStatus.Normal);
        }

        if (collision) {
            templateContent.setCollision(collision);
        }

        this.contentStage.addChild(templateContent);
    }

    private drawIndicators(): void {
        const unit = this.channel === EChannel.DIGITAL ? 'px' : 'mm';

        if (this.numberOfPages === 2) {
            const spreadWidthIndicator = new PixiSizeIndicator(this.templateStage.width, `${this.templateSize.width * 2} ${unit}`);
            spreadWidthIndicator.x = this.templateStage.width / 2;
            spreadWidthIndicator.y = -20;
            this.indicatorStage.addChild(spreadWidthIndicator);

            const pageWidthIndicator = new PixiSizeIndicator(this.templateStage.width / 2, `${this.templateSize.width} ${unit}`);
            pageWidthIndicator.x = this.templateStage.width / 4;
            pageWidthIndicator.y = this.templateStage.height + 20;
            this.indicatorStage.addChild(pageWidthIndicator);
        } else {
            const pageWidthIndicator = new PixiSizeIndicator(this.templateStage.width, `${this.templateSize.width} ${unit}`);
            pageWidthIndicator.x = this.templateStage.width / 2;
            pageWidthIndicator.y = -20;
            this.indicatorStage.addChild(pageWidthIndicator);
        }

        const pageHeightIndicator = new PixiSizeIndicator(this.templateStage.height, `${this.templateSize.height} ${unit}`);
        pageHeightIndicator.x = -20;
        pageHeightIndicator.y = this.templateStage.height / 2;
        pageHeightIndicator.rotation = -Math.PI / 2;

        this.indicatorStage.addChild(pageHeightIndicator);
    }

    private readonly memoizedCollisionDetection = memoize((contents: TemplateAreaModel[]) => {
        const collisions = new Set<TemplateAreaModel>();
        for (let i = 0; i < contents.length; i++) {
            const content1 = contents[i];
            for (let j = i + 1; j < contents.length; j++) {
                const content2 = contents[j];
                if (this.checkCollision(content1, content2)) {
                    collisions.add(content1);
                    collisions.add(content2);
                }
            }
        }
        return collisions;
    });

    private checkCollision(content1: TemplateAreaModel, content2: TemplateAreaModel): boolean {
        return (
            content1.position.x + content1.size.width > content2.position.x &&
            content1.position.x < content2.position.x + content2.size.width &&
            content1.position.y + content1.size.height > content2.position.y &&
            content1.position.y < content2.position.y + content2.size.height
        );
    }

}
